.slide_bg {
  color: green;
  width: 100vw;
  height: 100vh; 
  display: flex;
  flex: 1;
  order: 2;
  gap: 2px;
  justify-content: center;
  flex-wrap: wrap;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  animation: slidebg 20s linear infinite 0s;
  animation-timing-function: ease-in-out;
  background-image: url("../../images/animateimage/bn1.jpg");
}
@keyframes slidebg {
  /* 0% {
      background-image: url('../../images/animateimage/bn1.jpg');
  } */
  20% {
      background-image: url('../../images/animateimage/bn2.jpg');
  }
  40% {
      background-image: url('../../images/animateimage/bn3.jpg');
  }
  60% {
      background-image: url('../../images/animateimage/bn4.jpg');
  }
  80% {
      background-image: url('../../images/animateimage/bn5.jpg');
  }
  100% {
      background-image: url('../../images/animateimage/bn6.jpg');
  }
}

/* Media query for mobile phones */
@media only screen and (max-device-width: 768px) {
  .slide_bg {
      height: 50vh; /* Adjust as needed for mobile views */
  }
}

 