

.App-header {
  background-color: white;
  width:   200vh;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: brown;
}
.App-body {
  background-color: green;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-footer {
  background-color: success;
  min-height: 12vh;
 
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.contact-us,
.sign-up,
.marketing,
.consulting {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
 
  
}

.home {
  /* background-image: url('./images/img-1.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.services {
  background-image: url('./images/serviceimage.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 20px;
}

.products {
   background-image: url('./images/productimage.jpg'); 
  background-position: center;
  background-size: cover;
  text-align: center;
  background-repeat: no-repeat;
  color: rgb(2, 1, 1);
  font-size: 20px;
}

.contact-us {
  /* background-image: url('./images/img-4.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('./images/img-7.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.subsidiary {
  background-image: url('./images/bizcenter.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about {
  background-image: url('./images/aboutimage.jpg');
  background-position: center;
  text-align: left;
  padding: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #fefefe;
  font-size: 18px;
}
