
.atm {
  height: 50vh; 
  background: #CCCCCC;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 20vw; 
  margin: 1px auto;
}

.child {
  background-color: #FFFF00;
  width: 100vw;
  height: 100vh;
}

.form {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 5vw; 
}


/* Media query for mobile phones */
@media only screen and (max-device-width: 768px) {
  .atm {
      height: 70vh; 
      max-width: 90vw; 
  }

  .form {
      padding: 8vw; 
  }
}
