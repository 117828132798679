.dropdown-menu {
  background: red;
  width: 20%;
  position: absolute;
  top: 100px;
  list-style: none;
  text-align: start;
  opacity: 0.8;
  z-index: 9999;   /* Used to make the dropdown menu always on top */

}

.dropdown-menu li {
  background: green;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: green;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 40%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
