:root {
  --primary: red;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 1.2vw;
  /* Margin: 0.01%; */
  color: #fff;
  cursor: pointer;
  background-color: var(--primary);
  /* Media query for mobile phones */
  @media (max-width: 768px) {
    padding: 4vw 10vw; 
    font-size: 1vw; 
}
}

.btn:hover {
  padding: 6px 18px;
  transition: all 0.3s ease-out;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid var(--primary);
  transition: all 0.3s ease-out;
}
