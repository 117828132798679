.slider {
  position: absolute;
  top: 20%;
  left: 5%;
  width: 500%;
  height: 70%;
  animation: animate 20s linear infinite;
}

.slider .slide {
  position: relative;
  width: 20%;
  height: 100%;
  float: left;
}

.slider .slide .caption {
  position: absolute;
  bottom: 60px;
  left: 60px;
  right: 60px;
  padding: 30px;
  background: rgba(0, 0, 0, .5);
  box-sizing: border-box;
  transition: 0.8s;
}

.slider .slide .caption h2 {
  margin: 0 0 20;
  padding: 0;
  color: #fff;
  font-size: 48px;
  transition: 0.5s;
}

.slider .slide .caption p {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1.5vw;
  transition: 0.8s;
}

@media only screen and (max-device-width: 768px) {
  .slider {
      width: 100%;
      left: 0;
  }

  .slider .slide {
      width: 100%;
  }

  .slider .slide .caption {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      padding: 20px;
  }

  .slider .slide .caption h2 {
      margin: 0 0 10;
      font-size: 3vw;
  }

  .slider .slide .caption p {
      font-size: 2vw;
  }
}

@keyframes animate {
  0% {
      left: 0;
  }
  20% {
      left: 0;
  }
  25% {
      left: -100%;
  }
  45% {
      left: -100%;
  }
  50% {
      left: -200%;
  }
  70% {
      left: -200%;
  }
  75% {
      left: -300%;
  }
  95% {
      left: -300%;
  }
  100% {
      left: -400%;
  }
}
